import React from 'react';
import { Margins, Heading } from '../../../components';
import * as style from './Person.module.css';
import LeaPic from '../../../assets/images/lea.jpg';
import AndrasPic from '../../../assets/images/andras.jpg';
import TriinPic from '../../../assets/images/triin-jersov.jpg';

const Person = ({ image, texts, text, name, position }) => {
  const getImage = (image) => {
    if (!image) {
      return null;
    }

    switch (image) {
      case 'lea-saapar':
        return LeaPic;
      case 'andras':
        return AndrasPic;
      case 'triin-jersov':
        return TriinPic;
      default:
        return null;
    }
  };

  const picture = getImage(image);

  return (
    <div className={style['person']}>
      <div>
        {picture && (
          <figure className={style['person__figure']}>
            <img src={picture} alt={name} loading="lazy" className={style['person__image']} />
          </figure>
        )}
      </div>
      <Margins size="2x">
        <Margins>
          {texts.map((text, index) => (
            <p key={index}>{text}</p>
          ))}
        </Margins>
        <div>
          <Heading level="h3" visualLevel="h5" highlight>
            {name}
          </Heading>
          {position && <p>{position}</p>}
        </div>
      </Margins>
    </div>
  );
};

export default Person;
