import React from 'react';

import Layout from '../containers/Layout/Layout';
import { Container, Heading, Margins, Seo } from '../components';
import { Person } from '../containers/About';
import { useTranslation } from '../hooks';

const About = ({ pageContext: { currentPath } }) => {
  const { t, translations, locale } = useTranslation();

  return (
    <Layout>
      <Seo title={t('SEO.ABOUT.TITLE')} location={currentPath} lang={locale} />
      <Container>
        <Margins size="2x">
          <Heading level="h2" visualLevel="h5">
            {t('ABOUT.HEADER')}
          </Heading>
          {translations.ABOUT.PERSONS.map((person, index) => (
            <Person
              key={index}
              image={person.IMAGE}
              texts={person.TEXTS}
              name={person.NAME}
              position={person.POSITION}
            />
          ))}
        </Margins>
      </Container>
    </Layout>
  );
};

export default About;
